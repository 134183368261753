import { useState, useEffect } from 'react'
import { LogLevel } from '@microsoft/signalr'
import * as signalR from '@microsoft/signalr'
import { useAuth } from 'src/hooks/auth/useAuth'

const useSignalrPublic = (gameType) => {
  const [gameInfo, setGameInfo] = useState()
  const [gameOdds, setGameOdds] = useState()
  const [declare, setDeclare] = useState()
  const [payOut, setPayOut] = useState()
  const [lastCall, setLastCall] = useState()

  const [connectionState, setConnectionState] = useState()

  const { token } = useAuth()

  const newConnection = new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNAL_R_PUBLIC_URL, {
      accessTokenFactory: () => token?.accessToken,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build()

  const handleSignalRResponse = (message) => {
    const [messageType, data] = message.split('|')
    const publicData = JSON.parse(data)

    if (
      messageType === 'GameCreated' ||
      messageType === 'GameOpenBet' ||
      messageType === 'GameClosedBet' ||
      messageType === 'GameStart' ||
      messageType === 'GameEnd' ||
      messageType === 'GameCancelled'
    ) {
      setGameInfo(publicData)
    }
    if (messageType === 'GameLastCallBet') {
      setLastCall(publicData)
    }
    if (messageType === 'GameDeclareWinner') {
      setDeclare(publicData)
    }
    if (messageType === 'GamePayout') {
      setPayOut(publicData)
    }
    if (messageType === 'ODDS') {
      setGameOdds(publicData)
    }
  }

  const connectToSignalR = () => {
    newConnection
      .start()
      .then(() => {
        setConnectionState(newConnection.state)
        console.log('Connected public!')
        newConnection.send('JoinGameRoom', gameType)
      })
      .catch((e) => console.log('Connection failed: ', e))
  }

  newConnection.on('ReceivePublicUpdate', (message) => {
    handleSignalRResponse(message)
  })

  newConnection.onclose(() => {
    console.log(newConnection.state)
    setConnectionState(newConnection.state)
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (connectionState === 'Disconnected') {
        console.log('Reconnecting to Signal R')
        connectToSignalR()
      } else {
        clearInterval(interval)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [connectionState])

  useEffect(() => {
    if (newConnection.state === 'Disconnected') {
      connectToSignalR()
    }
  }, [gameType])

  return { gameInfo, gameOdds, declare, payOut, lastCall }
}

export default useSignalrPublic
